<template>
  <div class="d-flex justify-center">
    <v-icon v-for="(item, index) in items"
      :key="`social_${index}`"
      class="mx-3 icon"
      :color="color"
      @click="onClick(item.href)">{{item.icon}}</v-icon>
  </div>
</template>
<script>
export default {
  name: 'Social',
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [{
        label: 'Github',
        icon: 'mdi-github',
        href: '',
      }, {
        label: 'Gitlab',
        icon: 'mdi-gitlab',
        href: '',
      }, {
        label: 'WhatsApp',
        icon: 'mdi-whatsapp',
        href: '',
      }, {
        label: 'LinkedIn',
        icon: 'mdi-linkedin',
        href: '',
      }],
    }
  },
  methods: {
    onClick(href) {
      window.open(href, '_blank')
    },
  },
}

</script>
<style lang="scss"
  scoped>
.icon {
  opacity: 1;
  animation: heart 1s ease-in-out infinite;
  &:hover {
  //   animation: heart .4s ease-in-out infinite;
    transform: scale(3);
    color: red !important;
  }
}

@keyframes test {
  0% {
    transform: scale(0)
  }
  10% {
    transform: scale(0.5)
  }
  50% {
    transform: scale(.8)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes heart {
    0%,
    to {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    15% {
        opacity: .9;
        -webkit-transform: scale(1.2);
        transform: scale(1.2)
    }
    30% {
        -webkit-transform: scale(.95);
        transform: scale(.95)
    }
    45%,
    80% {
        opacity: .9;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
</style>
