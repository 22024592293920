<template>
  <v-app>
    <app-bar />
    <v-main class="ma-0 d-flex h-100 w-100 justify-center align-center">
      <div class="text-center title">Se vienen cositas...</div>
      <!-- <landing /> -->
      <!-- <mc-footer /> -->
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar'
// import McFooter from '@/components/McFooter'
// import Landing from '@/pages/Landing'

export default {
  name: 'App',
  components: {
    AppBar,
    // Landing,
    // McFooter,
  },
};
</script>
<style lang="scss">
  .w-100 {
    width: 100% !important;
  }
</style>
