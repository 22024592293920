<template>
  <v-app-bar
    app
    inverted-scroll
    color="secondary"
    dark>
    <template #img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0,.8)"></v-img>
    </template>
    <div class="d-flex align-center">
      <logo letters
        :scale="0.6" />
    </div>
    <v-spacer></v-spacer>
    <social color="accent" />
  </v-app-bar>
</template>
<script>
import Social from '@/components/Social'

export default {
  name: 'AppBar',
  components: { Social },
}

</script>
<style lang="css"
  scoped>
</style>
