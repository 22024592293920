import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#3292AB",
        secondary: "#065471",
        accent: "#FFC045",
        // error: '#D32F2F', //'#050302',
        // info: '#FFFEFF',
        // success: '#7a904a',
        // warning: '#f78012',
      },
    },
  },
});
