<template>
  <span class="d-block-inline logo--wrapper d-flex justify-center align-center"
    :style="customStyles"
    :class="`${letters ? 'transparent pa-0 ma-0' : 'mt-5 pa-4 secondary mx-auto'}`"
    >
    <div class="d-flex justify-center align-center"
      :class="`${letters ? 'transparent ma-0 pa-0' : 'ma-5 primary'}`"
    >
      <div class="circle d-flex flex-column justify-center align-center"
      :class="`${letters ? 'transparent ma-0 pa-0' : 'pa-8 secondary'}`"
    >
        <v-icon color="accent" :class="`${letters ? '' : 'mb-2'}`" size="40">mdi-guitar-acoustic</v-icon>
        <div class="brand accent--text font-weight-bold title text-center text-no-wrap">Max Castro</div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  name: 'Logo',
  props: {
    scale: {
      type: Number,
      default: 1,
    },
    letters: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customStyles() {
      return `transform: scale(${this.scale})`
    },
  },
}

</script>
<style lang="scss"
  scoped>
.logo {
  &--wrapper {
    border-radius: 3px;
    width: fit-content;
    .circle {
      width: 140px;
      height: 140px;
      border-radius: 50%;
    }
  }
}

</style>
